@import url('https://use.typekit.net/uyg3umz.css');
@import 'react-toastify/dist/ReactToastify.css';
@import '~react-image-gallery/styles/css/image-gallery.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --color-primary: 22 78 99;
  --color-secondary: 226 232 240;
  --color-success: 13 148 136;
  --color-info: 6 182 212;
  --color-warning: 245 158 11;
  --color-pending: 217 119 6;
  --color-danger: 185 28 28;
  --color-light: 241 245 249;
  --color-dark: 30 41 59;
  --color-slate-50: 248 250 252;
  --color-slate-100: 241 245 249;
  --color-slate-200: 226 232 240;
  --color-slate-300: 203 213 225;
  --color-slate-400: 148 163 184;
  --color-slate-500: 100 116 139;
  --color-slate-600: 71 85 105;
  --color-slate-700: 51 65 85;
  --color-slate-800: 30 41 59;
  --color-slate-900: 15 23 42;
}

body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont,
    'Segoe UI, ' Roboto ', sans-serif;', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  /* font-size: 100%; */
  box-sizing: border-box;
}

.app-btn,
.redirect-btn,
.link-btn {
  cursor: pointer;
}

.app-btn:hover,
.redirect-btn:hover,
.link-btn:hover {
  opacity: 0.6;
}

.ant-image-preview-img {
  display: inline-block;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.header-menu__dropdown {
  position: fixed !important;
}

.ant-spin-container {
  /* background: #e2e2e2; */
}
.ant-layout-content {
  width: 100%;
  background-color: #fff;
}

.ant-tooltip-inner {
  border-radius: 0.6rem;
}

.swiper {
  width: 100%;
  height: 100%;
}
/* .ant-dropdown {
  top: 12.4rem !important;
  left: 50% !important;
  transform: translate(-50%, 0) !important;
} */
.swiper-slide {
  text-align: center;
  font-size: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiper-pagination-bullet {
  background-color: #edf4ff !important;
}
.swiper-pagination-bullet-active {
  background-color: #d20c00 !important;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 4rem !important;
  padding: 0.8rem 1.4rem !important;
  /* @media screen and (max-width: 768px) {
    height: 3.3rem !important;
  } */
}
.Toastify__toast-container {
  width: 52.4rem;
}

.Toastify__toast {
  box-shadow: 0px 1px 16px 0px rgba(56, 56, 56, 0.15);
  border-radius: 16px;
  padding: 0;
}
.Toastify__toast-body {
  padding: 0;
}
.ant-select-selection-placeholder {
  color: #888888 !important;
  font-family: Roboto-Regular;
  font-weight: 400;
  line-height: 19.6px;
}
.ant-btn-primary {
  background: #d20c00 !important;
  border-color: #d20c00 !important;
}
.ant-btn-primary > span {
  color: white !important;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  background: #d20c00 !important;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner:before {
  border-color: #d20c00 !important;
}
.ant-picker-cell-in-view.ant-picker-cell-in-range:before {
  background: #fff4f3 !important;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):before {
  background: #fff4f3 !important;
}
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end:before {
  background: #fff4f3 !important;
}
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):after {
  border-color: #d20c00 !important;
}
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range):after {
  border-color: #d20c00 !important;
}
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):after {
  border-color: #d20c00 !important;
}
@font-face {
  font-family: 'Roboto-Light';
  font-weight: 300;
  src: url('fonts/Roboto-Light.ttf');
}
@font-face {
  font-family: 'Roboto-Light';
  font-weight: 300;
  src: url('fonts/Roboto-Light.ttf');
}
@font-face {
  font-family: 'Roboto-Medium';
  font-weight: 400;
  src: url('fonts/Roboto-Medium.ttf');
}
@font-face {
  font-family: 'Roboto-Regular';
  font-weight: 400;
  src: url('fonts/Roboto-Regular.ttf');
}
@font-face {
  font-family: 'Roboto-Bold';
  font-weight: 700;
  src: url('fonts/Roboto-Bold.ttf');
}

@font-face {
  font-family: 'Roboto-Bold';
  font-weight: 800;
  src: url('fonts/Roboto-Bold.ttf');
}

.app-text,
span,
label,
p,
b,
i,
a,
strong,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Roboto-Regular;
  margin-bottom: 0;
  color: #383838;
  font-size: 1.4rem;
}

.ant-popover-inner {
  border-radius: 6px;
}
.notification-message .title {
  color: white;
}
/* .text-xs {
  font-size: 1.2rem;
  line-height: 1.6rem;
}
.text-sm {
  font-size: 1.4rem;
  line-height: 2rem;
}
.text-base {
  font-size: 1.6rem;
  line-height: 2.4rem;
}
.text-lg {
  font-size: 1.8rem;
  line-height: 2.8rem;
}
.text-xl {
  font-size: 2rem;
  line-height: 2.8rem;
}
.text-2xl {
  font-size: 2.4rem;
  line-height: 3.2rem;
}
.text-3xl {
  font-size: 3rem;
  line-height: 3.6rem;
}
.text-4xl {
  font-size: 3.6rem;
  line-height: 4rem;
}
.text-5xl {
  font-size: 4.8rem;
  line-height: 1;
}
.text-6xl {
  font-size: 6rem;
  line-height: 1;
}
.text-7xl {
  font-size: 7.2rem;
  line-height: 1;
}
.text-8xl {
  font-size: 9.6rem;
  line-height: 1;
}
.text-9xl {
  font-size: 12.8rem;
  line-height: 1;
} */
